<template>
  <b-container class="galeria" v-loading="loading">
    <div class="galeria-title">
      <h3>{{ galeria.gaf_titulo }}</h3>
    </div>
    <div class="galeria-date pb-2">
      {{ galeria.gaf_data | formatDate("LL") }}
    </div>
    <div class="galeria-desc" v-if="galeria.gaf_descricao">
      <p>{{ galeria.gaf_descricao }}</p>
    </div>
    <div class="see-all pb-3">
      <a href="/galerias" class="text-right"
        ><span class="all-links">Ver outras Galerias</span></a
      >
    </div>

    <div class="galeria-images">
      <ul>
        <li v-for="(item, index) in galeria.image" :key="index">
          <img :src="item.image" alt="" />
        </li>
        <!-- <li></li> -->
      </ul>
    </div>
    <div class="see-all pb-3 pt-3">
      <a href="/galerias" class="text-right"
        ><span class="all-links">Ver outras Galerias</span></a
      >
    </div>
  </b-container>
</template>

<script>
export default {
  name: "galeria",
  data() {
    return {
      galeria: [],
      loading: false,
    };
  },
  methods: {
    async getGaleria(id) {
      this.loading = true;
      this.galeria = [];
      const res = await this.$axios.get(`galerias/${id}`).catch((e) => {
        console.error("Noticia", e);
      });
      if (res.status === 200) {
        this.galeria = res.data.filter(
          (galeria) => galeria.gaf_cod.toString() === id
        )[0];
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getGaleria(this.$route.params.id);
  },
};
</script>

<style lang="scss">
.galeria {
  padding: 30px 20px;
  font-family: "Roboto", sans-serif;
  font-size: var(--font-size--default);
  color: #2c456a;
  h3 {
    font-weight: bold;
  }
  .see-all {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
  .text-right {
    width: 150px;
    height: 30px;
    background-color: var(--color-text-title);
    color: #ffffff;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: #4d66bf !important;
      text-decoration: none;
    }
  }
  &-desc {
    text-align: left;
    margin: 0 20px;
  }
  &-images {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      margin: 5px 0 0 5px;
      height: 45vh;
      // flex-grow: 1;
    }
    // li:last-child {
    //   flex-grow: 10;
    // }

    img {
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }

    @media (max-aspect-ratio: 1/1) {
      li {
        height: 30vh;
      }
    }
    // Short screens

    @media (max-height: 480px) {
      li {
        height: 80vh;
      }
    }

    // Smaller screens in portrait

    @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
      ul {
        flex-direction: row;
      }

      li {
        height: auto;
        width: 100%;
      }
      img {
        width: 100%;
        max-height: 75vh;
        min-width: 0;
      }
    }
  }
}
</style>
